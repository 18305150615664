import React from 'react';
import HeroBlock from '../../components/HeroBlock';
import Testimonials from '../../components/Testimonials';
import Layout from '../../components/Layout';
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Seo from '../../components/Seo'
import { StyledTimeline, TimelineItem, TimelineContent } from '../../components/Timeline';
import DirectusForm from '../../components/DirectusForm';

export default function Home() {

  return (

    <Layout>
      <Seo 
        lang="en"
        description="Description here"
        title="Title here"
      />


    <HeroBlock 
        bgcolor="brand" 
        content={(
          <>
            <h1>Ready to Join the AI Leadership Elite? Here's Your Invitation to the Top.</h1>
            <ul>
              <li>Discover the secrets to AI mastery and leadership transformation</li>
              <li>See how leaders like you are applying AI in the real world</li>
              <li>Step into the circle of AI-savvy leaders</li>
            </ul>
            
          </>
        )}        
      />


      
      <HeroBlock 
        bgcolor="brandSecondary" 
        imagealign="left"
        image={(
          <div style={{textAlign:"center"}}>
            <StaticImage 
              style={{
                margin:"0 auto",
                borderRadius:"50%",
                border:"3px solid #fff",
                boxShadow:"0px 0px 4px #666"
              }}
              width={240}
              height={240}
              src="../../../content/images/alexandra-1.png" alt="" 
            />
          </div>
        )}
        content={(
          <>
            <h3>What our pilot customers say?</h3>
            <blockquote>
              <p>Participating in reverse mentoring sessions has been a profound journey for me. I've gained confidence in leveraging this knowledge to innovate within my role. AI has turned from a daunting challenge into most exciting opportunity for growth.</p>
              <cite>Alexandra, Marketing Director</cite>
            </blockquote>
          </>
        )}        
      />     

    
    
    <HeroBlock 
        bgcolor="brandSecondary" 
        image={(
          <div style={{textAlign:"center"}}>
            <StaticImage 
              style={{
                margin:"0 auto",
                borderRadius:"50%",
                border:"3px solid #fff",
                boxShadow:"0px 0px 4px #666"
              }}
              width={240}
              height={240}
              src="../../../content/images/emily-1.png" alt="" 
            />
          </div>
        )}
        content={(
          <>
            <h3>Meet the reverse mentors</h3>
            <blockquote>
              <p>Emily  is a recent graduate from Stanford University, where she specialized in AI and business. She serves as a reverse mentor, guiding seasoned executives through the intricacies of AI applications in business, fostering long-term professional growth and digital transformation.</p>
              <cite>Emily, Reverse Mentor</cite>
            </blockquote>
          </>
        )}        
      />     

      <HeroBlock 
        bgcolor="brand" 
        imagealign="left"
        headline={(
          <h2>Hetki.io is your Personal Trainer for Executive AI skills</h2>
        )}
        image={(
          <>
            <StaticImage 
              style={{
                mixBlendMode:"screen",
                borderRadius:"0%",
              }}
              transformOptions={{fit: "cover", cropFocus: "centre" }}
              height={280}
              width={400}
              src="../../../content/images/reverse-mentoring-process-1.webp" alt="" 
            />
          </>
        )}
        content={(
          <>
            <h4>Science-based AI Reverse Mentoring</h4>
            <ul>
              <li>Reverse mentoring means pairing experienced leaders with younger AI-native individuals.</li>
              <li>Hetki.io Reverse Mentoring sessions are science based and tailored to your specific needs and goals.</li>  
              <li>The service is unique to executive AI skills development, emphasizing practical use and technical knowledge of AI tools.</li>
            </ul>
          </>
        )}        
      />

      <HeroBlock 
        bgcolor="brand" 
        content={(
          <>
            <StyledTimeline accentcolor="brandSecondary">
            <TimelineItem accentcolor="brandSecondary">
              <TimelineContent>
                <h2>Dive into AI without the need for a tech degree</h2>
                <p>Our Accessible AI Learning approach integrates seamlessly into your leadership toolkit, ensuring that the mental effort required is focused, purposeful, and directly applicable to your daily decisions and strategies.</p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem accentcolor="brandSecondary">
              <TimelineContent>           
                <h2>Designed for the busy executive</h2>
                <p>Start applying AI to real-world problems immediately. No courses, no jargon - just impactful, actionable insights. Arrange meetings when it suits you, seamlessly integrating AI mastery into your routine.</p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem accentcolor="brandSecondary">
              <TimelineContent>
                <h2>Cost-Effective Professional Development</h2>
                <p>Leverage your company's training budget to ensure your executives are equipped with the AI skills essential for tomorrow's challenges, all without the financial burden of traditional training programs. </p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem accentcolor="brandSecondary">
            <TimelineContent>
                <h2>Effortless Learning Experience</h2>
                <p>Embrace the ease of acquiring AI skills from the comfort of your office or anywhere in the world, reducing physical effort to nearly zero. For those who prefer a more traditional approach, we also provide the option of face-to-face meetings.</p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem accentcolor="brandSecondary">
            <TimelineContent>
                <h2>Sign up, and we guide you through every step</h2>
                <p>While starting with our reverse mentoring platform is straightforward, we admit that adopting new routines and skills takes time. Our structured program is tailored to fit within your busy life, ensuring that each new AI concept and tool becomes a natural extension of your leadership practice.</p>
              </TimelineContent>
            </TimelineItem>
          </StyledTimeline>
          </>
        )}        
      />
 
      <HeroBlock
        bgcolor="dark"
        imagealign="left"
        headline={(
          <>
            <h2>Want to know more?</h2>
            <p>We are currently working at full capacity with our pilot customers and have only limited ability to onboard new members. Please join our waiting list to receive updates and a chance for free trial to hetki.io.</p>

          </>

        )}
        image={(
          <>
            <StyledTimeline accentcolor="brandTertiary">
            <TimelineItem accentcolor="brandTertiary">
              <TimelineContent>           
                <h2>Join the waiting list</h2>
                <p>It is simple, just provide your contact details so we know how to reach you.</p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem accentcolor="brandTertiary">
              <TimelineContent>
                <h2>Receive updates</h2>
                <p>We will tell you about our company progress and let you know once we have ability to onboard new members.</p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem accentcolor="brandTertiary">
              <TimelineContent>
                <h2>Receive a free trial</h2>
                <p>We will offer you a chance to test our offering without commitment.</p>
              </TimelineContent>
            </TimelineItem>
          </StyledTimeline>
          </>
        )}
        content={(
          <DirectusForm id="waiting_list_submission" />
        )}
        />

      <HeroBlock bgcolor="light"
        image={(
          <div style={{textAlign:"center"}}>
            <StaticImage 
              style={{
                margin:"0 auto",
                borderRadius:"50%",
                border:"3px solid #fff",
                boxShadow:"0px 0px 4px #666"
              }}
              width={240}
              height={240}
              src="../../../content/images/founder-3.png" alt="" 
            />
          </div>
        )}
        content={(
          <>
          <h2>A startup company from Helsinki</h2>
          <blockquote>
            <p>We founded hetki.io because we believe AI will have a huge impact to our lives in the upcoming years. We wanted to bridge the gap in AI skills among corporate decision makers and facilitate direct knowledge transfer from the younger, AI-native generation.</p>
            <cite>Teemu Takala, founder and CEO</cite>
          </blockquote>
          </>
        )}
      />
      <HeroBlock bgcolor="brand"
        headline={(
          <h2>Feedback from our pilot customers</h2>
        )}
        content={(
            <Testimonials
              data={[
                { name:"Casey", title:"Sales Director", content: "The reverse mentoring program truly opened my eyes. It's not just technology; it's a new way of thinking. My advice? Dive in, the transformation is real and deeply rewarding."},
                { name:"Sara", title:"CEO", content: "The mentorship provided has been invaluable, offering clarity and confidence to innovate and lead."},
                { name:"Alex", title:"Chairman of the board", content: "The sessions are enlightening, pushing me to explore and grow in exciting new directions."},
                { name:"Morgan", title:"CEO", content: "Learning about AI in such a hands-on manner has demystified the technology for me, turning apprehension into action. The sessions were not just informative but truly transformative, sparking ideas that I'm now eagerly implementing."},
                { name:"Sam", title:"Chief Revenue Officer", content: "This experience was a game-changer. Embracing AI has revolutionized how I approach my role, turning challenges into opportunities for innovation. The guidance received has been pivotal, encouraging me to think differently and boldly apply new concepts."},              
              ]}
            />  
        
 
        )}
      />

    </Layout>

  )

}
